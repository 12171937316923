var tpj = jQuery;
var revapi;
tpj(document).ready(function() {
	if (tpj("#rev_slider_bps").revolution == undefined) {
		revslider_showDoubleJqueryError("#rev_slider_bps");
	} else {
		revapi = tpj("#rev_slider_bps").show().revolution({
			sliderType : "hero",
			jsFileLocation : "../../revolution/js/",
			sliderLayout:"fullscreen",
			dottedOverlay:"none",
			delay:0,
			responsiveLevels:[1240,1240,1240,480],
			visibilityLevels:[1240,1240,1240,480],
			gridwidth:[1240,1024,778,480],
			gridheight:[868,768,960,720],
			lazyType:"single",
			parallax: {
				type:"off",
				origo:"slidercenter",
				speed:2000,
				levels:[2,3,4,5,6,7,12,16,10,50,47,48,49,50,51,55],
				type:"mouse",
				disable_onmobile:"on"
			},
			shadow:0,
			spinner:"off",
			autoHeight:"off",
			fullScreenAutoWidth:"off",
			fullScreenAlignForce:"off",
			fullScreenOffsetContainer: "",
			fullScreenOffset: "0",
			disableProgressBar:"on",
			hideThumbsOnMobile:"off",
			hideSliderAtLimit:0,
			hideCaptionAtLimit:0,
			hideAllCaptionAtLilmit:0,
			debugMode:false,
			stopAfterLoops: -1,
			stopAtSlide: -1,
			viewPort:{
				enable: true,
				outof: 'wait',
				visible_area: '60%'
			}
		});
	}
});	/*ready*/